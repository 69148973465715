<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-white border-bottom">
    <div class="container-xl">
      <a class="navbar-brand fw-bold text-primary" href="/"
        ><i class="bi bi-cloud-check"></i> Update Server</a
      >
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarsExample09"
        aria-controls="navbarsExample09"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarsExample09">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link
              class="nav-link"
              :class="'' === currentPath ? 'active' : ''"
              to="/"
              @click="setCurrentPath('')"
              >Dashboard</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              :class="'logs' === currentPath ? 'active' : ''"
              to="/logs"
              @click="setCurrentPath('logs')"
              >Logs</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              :class="'documentation' === currentPath ? 'active' : ''"
              to="/documentation"
              @click="setCurrentPath('documentation')"
              >Documentation</router-link
            >
          </li>
        </ul>
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link" href="#">
              <i class="bi bi-qr-code-scan"></i>
            </a>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              :class="'account' === currentPath ? 'active' : ''"
              to="/account"
              @click="setCurrentPath('account')"
              >{{
                currentUser.first_name + " " + currentUser.last_name
              }}</router-link
            >
          </li>
          <li class="nav-item">
            <span
              class="nav-link"
              aria-current="page"
              @click="logout"
              style="cursor: pointer"
              ><i class="bi bi-power"></i
            ></span>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      expanded: false,
      isAdmin:
        process.appdata.user &&
        process.appdata.user.root_organization &&
        process.env.VUE_APP_RT_NUMBER ===
          process.appdata.user.rootOrganization.company_number
          ? true
          : false,
      currentUser: process.appdata.user,
      currentPath: window.location.pathname.split("/")[1],
    };
  },
  methods: {
    setCurrentPath(path) {
      this.currentPath = path;
    },
    collapse() {
      this.expanded = false;
    },
    logout() {
      process.appdata.logout();
    },
  },
};
</script>
