<template>
  <Breadcrumbs :breaditems="[{ title: 'Page Not Found' }]" />
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="text-center my-5">
          <h1>Oops!</h1>
          <p class="lead">404 Not Found</p>
          <div class="py-3">
            Sorry, an error has occurred, Requested page not found!
          </div>
          <div class="error-actions">
            <a href="/" class="btn btn-link btn-lg"
              ><i class="bi bi-house-door-fill me-2"></i>
              <small class="text-secondary">Take Me Home</small>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "../components/Breadcrumbs.vue";

export default {
  name: "NotFound",
  components: { Breadcrumbs },
};
</script>
