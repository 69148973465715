<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">New Firmware</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 mb-3">
              <label for="model_ref" class="form-label">Model</label>
              <select
                id="model_ref"
                class="form-control"
                v-model="model_ref"
                @change="validate"
              >
                <option value="">Please select</option>
                <option
                  v-for="model in models"
                  v-bind:key="model.ref"
                  :value="model.ref"
                >
                  {{ model.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-3">
              <label for="release_type" class="form-label">Release Type</label>
              <select
                id="release_type"
                class="form-control"
                v-model="release_type"
                @change="validate"
              >
                <option value="">Please select</option>
                <option value="production">Production</option>
                <option value="staging">Staging</option>
                <option value="development">Development</option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-3">
              <label for="release_notes" class="form-label"
                >Release Notes</label
              >
              <input
                type="type"
                id="release_notes"
                class="form-control"
                v-model="release_notes"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-3">
              <label for="release_impact" class="form-label"
                >Release Impact</label
              >
              <select
                id="release_impact"
                class="form-control"
                v-model="release_impact"
                @change="validate"
              >
                <option value="">Please select</option>
                <option value="major">Major</option>
                <option value="minor">Minor</option>
                <option value="patch">Patch</option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-3">
              <div id="modalDropArea">
                <i class="bi bi-file-binary-fill h1"></i>
                <p id="pFileInfo" class="text-primary lead mt-3">
                  Upload a file or drag and drop
                </p>
                <p class="text-secondary lead">Up to {{firmware_size_limit()}}</p>

                <input
                  type="file"
                  id="modalDropTarget"
                  :onchange="selectFile"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <button
                type="button"
                class="btn btn-primary w-100"
                :disabled="!valid_input"
                @click="add_firmware"
              >
                Add firmware
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Storage from "../../helpers/storage";

export default {
  name: "NewFirmware",
  props: ["id"],
  data() {
    return {
      models: [],
      model_ref: "",
      release_type: "",
      release_notes: "",
      release_impact: "",
      file: null,
      valid_input: false,
    };
  },
  mounted() {
    this.fetch_models();
  },
  methods: {
    firmware_size_limit() {
      let decimals = 2;
      let interval = 1024;
      let bytes = interval * process.env.MIX_UPLOAD_SIZE_LIMIT_KB;

      if (bytes === 0) return '0 Bytes';

      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      const i = Math.floor(Math.log(bytes) / Math.log(interval));

      return parseFloat((bytes / Math.pow(interval, i)).toFixed(dm)) + ' ' + sizes[i];
    },

    validate() {
      this.valid_input =
        "" !== this.model_ref &&
        "" !== this.release_type &&
        "" !== this.release_impact &&
        null !== this.file;
    },

    fetch_models() {
      var sessionTokens = Storage.get("session_tokens", true),
        umsTokens = Storage.get("tokens");

      axios
        .get(process.env.MIX_APP_URL + "/api/v1/device-models", {
          headers: {
            Authorization:
              sessionTokens.token_type + " " + sessionTokens.access_token,
            "UMS-Authorization": umsTokens,
          },
        })
        .then((response) => {
          this.models = response.data;
        })
        .catch((error) => {
          process.appdata.refreshTokens();
        });
    },

    add_firmware() {
      let app = this;

      var sessionTokens = Storage.get("session_tokens", true),
        umsTokens = Storage.get("tokens");

      var data = new FormData();
      data.append("model", this.model_ref);
      data.append("release_type", this.release_type);
      data.append("release_notes", this.release_notes);
      data.append("release_impact", this.release_impact);
      data.append("image", this.file, this.file.name);

      axios
        .post(process.env.MIX_APP_URL + "/api/v1/firmware/", data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization:
              sessionTokens.token_type + " " + sessionTokens.access_token,
            "UMS-Authorization": umsTokens,
          },
        })
        .then((response) => {
          this.emitter.emit("firmware_uploaded");

          let newFactoryModal = bootstrap.Modal.getInstance(
            document.getElementById(this.id)
          );
          newFactoryModal.hide();

          this.emitter.emit("popup_alert", {
            type: "success",
            text: "Firmware was succesfuly uploaded!",
          });
        })
        .catch(function (error) {
          if (error.response) {
            let errorMsg = "";

            for (let key in error.response.data) {
              errorMsg += error.response.data[key].join(" ");
            }

            app.emitter.emit("popup_alert", {
              type: "danger",
              text: errorMsg,
            });
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    selectFile(ev) {
      var app = this;

      var fileObj = ev.target.files[0],
        reader = new FileReader();

      reader.onloadend = function (blob) {
        app.file = fileObj;

        document.getElementById("pFileInfo").innerHTML =
          fileObj.name + " (" + Math.round(fileObj.size / 1024) + "KB)";

        app.validate();
      };

      var blob = fileObj.slice(0, fileObj.size);
      reader.readAsBinaryString(blob);
    },
  },
};
</script>