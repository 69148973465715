<template>
  <Navbar />
  <router-view />
  <Footer />
  <PopupAlert type="alert" text="That was cool!" />
</template>

<script>
import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';
import PopupAlert from './components/PopupAlert.vue';

export default {
  name: "App",
  components: {
    Navbar,
    Footer,
    PopupAlert,
  },
};
</script>