<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete Firmware</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="alert alert-danger text-center">
                Are you sure you to delete version <b>{{ version }}</b
                >?
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <button class="btn btn-secondary w-100" data-bs-dismiss="modal">
                Cancel
              </button>
            </div>
            <div class="col-md-6">
              <div class="btn btn-danger w-100" @click="delete_firmware">
                Confirm
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Storage from "../../helpers/storage";

export default {
  name: "FirmwareDelete",
  props: ["id"],
  data() {
    return {
      firmware_id: "",
      model: {},
      version: "",
    };
  },
  mounted() {
    this.emitter.on("firmware_details_loaded", (data) => {
      this.firmware_id = data.id;
      this.model = data.model;
      this.version = data.version;
    });
  },
  methods: {
    delete_firmware() {
      var sessionTokens = Storage.get("session_tokens", true),
        umsTokens = Storage.get("tokens");

      axios
        .delete(
          process.env.MIX_APP_URL + "/api/v1/firmware/" + this.model.ref + "/" + this.version,
          {
            headers: {
              Authorization:
                sessionTokens.token_type + " " + sessionTokens.access_token,
              "UMS-Authorization": umsTokens,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            let firmwareDeleteModal = bootstrap.Modal.getInstance(
              document.getElementById(this.id)
            );
            firmwareDeleteModal.hide();

            this.emitter.emit("popup_alert", {
              type: "success",
              text: "Firmware was succesfuly deleted!",
            });

            setTimeout(() => {
              window.location.href = "/";
            }, 2500);
          } else {
            process.appdata.umslogin();
          }
        });
    },
  },
};
</script>