<template>
  <Breadcrumbs :breaditems="[{ title: 'Logs' }]" />
  <div class="container-xl">
    <div class="d-flex bd-highlight mb-2">
      <div class="me-2">
        <div class="input-group">
          <span class="input-group-text border-end-0 bg-white" id="basic-addon1"
            ><i class="bi bi-circle"></i></span
          ><select
            v-model="filter.log_type"
            class="form-select bg-white border-start-0 ps-0"
            style="max-width: 225px"
          >
            <option value="---" disabled="">Status</option>
            <option value="">All Logs</option>
            <option value="device_created">Device created</option>
            <option value="device_deleted">Device deleted</option>
            <option value="device_updated">Device updated</option>
          </select>
        </div>
      </div>
      <div class="me-2">
        <div class="input-group">
          <span class="input-group-text border-end-0 bg-white" id="basic-addon1"
            ><i class="bi bi-building"></i></span
          ><select
            v-model="filter.organisation"
            class="form-select bg-white border-start-0 ps-0"
            style="max-width: 125px"
          >
            <option value="---" disabled>Organisation</option>
            <option value="">All</option>
            <option
              v-for="organisation in organisations"
              v-bind:key="organisation.id"
              :value="organisation.id"
            >
              {{ organisation.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="me-2">
        <div class="input-group">
          <span class="input-group-text border-end-0 bg-white" id="basic-addon1"
            ><i class="bi bi-person-badge"></i></span
          ><select
            v-model="filter.assignee"
            class="form-select bg-white border-start-0 ps-0"
            style="max-width: 125px"
          >
            <option value="---" disabled>Assignee</option>
            <option value="">All</option>
            <option
              v-for="assignee in assignees"
              v-bind:key="assignee.id"
              :value="assignee.id"
            >
              {{ assignee.first_name + " " + assignee.last_name }}
            </option>
          </select>
        </div>
      </div>
      <div class="me-2">
        <div class="input-group">
          <span class="input-group-text border-end-0 bg-white" id="basic-addon1"
            ><i class="bi bi-person-bounding-box"></i></span
          ><select
            v-model="filter.admin"
            class="form-select bg-white border-start-0 ps-0"
            style="max-width: 125px"
          >
            <option value="---" disabled>Admin</option>
            <option value="">All</option>
            <option
              v-for="admin in admins"
              v-bind:key="admin.id"
              :value="admin.id"
            >
              {{ admin.first_name + " " + admin.last_name }}
            </option>
          </select>
        </div>
      </div>
      <div class="me-2">
        <div class="input-group">
          <div class="input-group">
            <span class="input-group-text bg-white"
              ><i class="bi bi-calendar-week"></i></span
            ><input
              type="text"
              id="fromDate"
              placeholder="From Date"
              class="form-control datepicker-input"
              style="max-width: 120px"
            /><span class="input-group-text bg-white"
              ><i class="bi bi-calendar-week"></i></span
            ><input
              type="text"
              id="toDate"
              placeholder="To Date"
              class="form-control datepicker-input"
              style="max-width: 120px"
            />
          </div>
        </div>
      </div>
      <div class="ms-auto">
        <div class="input-group">
          <span class="input-group-text bg-white border-end-0"
            ><i class="bi bi-search"></i></span
          ><input
            v-model="filter.activity_name"
            type="text"
            class="form-control border-start-0"
            v-on:keyup.enter="fetch_activities()"
            placeholder="Search..."
          />
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header bg-light">Activity Logs</div>
      <div class="list-group list-group-flush">
        <router-link
          v-for="activity in activities"
          v-bind:key="activity.id"
          :data-user="activity.causer_id"
          :to="'/logs/' + activity.id"
          class="list-group-item list-group-item-action"
        >
          <div class="d-flex align-items-center">
            <div class="flex-shrink-0">
              <img
                src="/images/person-circle.svg"
                :alt="activity.causer_name"
                class="rounded-circle align-self-center"
                width="36"
                height="36"
                onerror="this.src='/images/person-circle.svg';"
              />
            </div>
            <div class="flex-grow-1 ms-3">
              <span class="text-dark">{{ activity.causer_name }}</span>
              <p
                class="small text-muted mb-0 fw-normal"
                v-html="activity.plaintext ? activity.plaintext : '&nbsp;'"
              ></p>
            </div>
            <span
              class="text-muted float-end small text-nowrap"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title=""
              :data-bs-original-title="activity.created_at"
              >{{ timeAgo(activity.created_at) }}</span
            >
            <div class="float-end ms-3">
              <i class="bi bi-chevron-right align-self-center"></i>
            </div>
          </div>
        </router-link>
      </div>
      <pagination
        v-if="paginator.current_page"
        :records="paginator.total_items"
        :per-page="10"
        v-model="current_page"
        :options="paginatorOptions"
      ></pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Pagination from "v-pagination-3";
import { Datepicker } from "vanillajs-datepicker";

import Utils from "../helpers/utils";
import Breadcrumbs from "../components/Breadcrumbs.vue";
import RTPagination from "../components/RTPagination.vue";

export default {
  name: "Logs",
  components: { Breadcrumbs, Pagination, RTPagination },
  data() {
    return {
      paginatorOptions: {
        template: RTPagination,
      },
      paginator: {},
      organisations: [],
      assignees: [],
      entries: [],

      filter: {
        activity_name: "",
        log_type: "",
        organisation: "",
        assignee: "",
        admin: "",
        from_date: "",
        to_date: "",
      },
      current_page: 1,
    };
  },
  mounted() {
    let app = this;

    const fromDate = new Datepicker(document.getElementById("fromDate"), {
      clearBtn: true,
      autohide: true,
      maxDate: new Date(),
      format: "d M yyyy",
    });

    const toDate = new Datepicker(document.getElementById("toDate"), {
      clearBtn: true,
      autohide: true,
      minDate: new Date(),
      maxDate: new Date(),
      format: "d M yyyy",
    });

    document.getElementById("fromDate").addEventListener("changeDate", (ev) => {
      app.filter.from_date = Datepicker.formatDate(
        ev.detail.date,
        "yyyy-mm-dd"
      );

      toDate.setOptions({ minDate: ev.detail.date });

      if (toDate.getDate() < ev.detail.date) {
        toDate.setDate(ev.detail.date);
      }
    });

    document.getElementById("toDate").addEventListener("changeDate", (ev) => {
      this.filter.to_date = Datepicker.formatDate(ev.detail.date, "yyyy-mm-dd");
    });

    this.fetch_organisations();
    this.fetch_users();

    setTimeout(() => {
      jQuery("[data-bs-toggle='tooltip']").each(function () {
        jQuery(this).tooltip();
      });
    }, 1000);
  },
  methods: {
    fetch_activities() {
      let url =
        process.env.MIX_RT_LOGS_ENDPOINT +
        "/activities?page=" +
        this.current_page +
        "&sort=created_at,desc&filter[type]=" +
        this.filter.log_type +
        "&filter[start]=" +
        this.filter.from_date +
        "&filter[end]=" +
        this.filter.to_date +
        "&filter[wildcard]=" +
        encodeURIComponent(this.filter.activity_name);

      if ("" !== this.filter.assignee) {
        url += "&filter[causer_id]=" + this.filter.assignee;
      } else if ("" != this.filter.organisation) {
        url +=
          "&filter[properties.key]=organization.id&filter[properties.value]=" +
          this.filter.organisation;
      }

      axios
        .get(url, {
          headers: {
            Authorization: process.env.MIX_RT_LOGS_SECRET,
          },
        })
        .then((response) => {
          this.activities = response.data.data;
          this.paginator = response.data.paginator;

          this.fetch_avatars();
        });
    },
    fetch_organisations() {
      axios
        .get(process.env.MIX_RT_UMS_URL + "organizations?items=1000", {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.organisations = response.data.data;
        });
    },
    fetch_users() {
      this.filter.assignee = "";
      this.filter.admin = "";

      let url = process.env.MIX_RT_UMS_URL;

      if ("" !== this.filter.organisation) {
        url += "organizations/" + this.filter.organisation + "/users";
      } else {
        url += "admin/users?items=1000&filter[user.full_name]=";
      }

      axios
        .get(url, {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          if ("" !== this.filter.organisation) {
            this.assignees = response.data[0] ? response.data[0].users : [];
            this.admins = response.data[0] ? response.data[0].users : [];
          } else {
            this.assignees = response.data.data;
            this.admins = response.data.data;
          }

          this.fetch_activities();
        });
    },
    fetch_avatars() {
      let userIds = {};

      for (let item in this.activities) {
        userIds[this.activities[item].causer_id] = 1;
      }

      axios
        .get(
          process.env.MIX_RT_UMS_URL +
            "users-avatars?users=" +
            Object.keys(userIds).join(","),
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            let users = response.data;

            for (let item in users) {
              jQuery("[data-user='" + users[item].id + "'] img").attr(
                "src",
                users[item].avatar
              );
            }
          } else {
            process.appdata.logout();
          }
        });
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
  },
  watch: {
    "filter.log_type": function () {
      this.fetch_activities();
    },
    "filter.organisation": function () {
      this.fetch_users();
    },
    "filter.assignee": function () {
      this.fetch_activities();
    },
    "filter.admin": function () {
      this.fetch_activities();
    },
    "filter.from_date": function () {
      this.fetch_activities();
    },
    "filter.to_date": function () {
      this.fetch_activities();
    },
    current_page: function () {
      this.fetch_activities();
    },
  },
};
</script>