<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Update release</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 mb-3">
              <label for="release_type" class="form-label">Release Type</label>
              <select
                id="release_type"
                class="form-control"
                v-model="release_type"
              >
                <option value="">Please select</option>
                <option value="production">Production</option>
                <option value="staging">Staging</option>
                <option value="development">Development</option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <button
                type="button"
                class="btn btn-primary w-100"
                @click="update_release"
              >
                Update Release
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Storage from "../../helpers/storage";

export default {
  name: "FirmwareRelease",
  props: ["id"],
  data() {
    return {
      firmware_version: "",
      model: {},
      release_type: "",
    };
  },
  mounted() {
    this.emitter.on("firmware_details_loaded", (data) => {
      this.firmware_version = data.version;
      this.model = data.model;
      this.release_type = data.release_type;
    });
  },
  methods: {
    update_release() {
      var sessionTokens = Storage.get("session_tokens", true),
        umsTokens = Storage.get("tokens");

      axios
        .patch(
          process.env.MIX_APP_URL + "/api/v1/firmware/" + this.model.ref + "/" + this.firmware_version,
          {
            release_type: this.release_type,
          },
          {
            headers: {
              Authorization:
                sessionTokens.token_type + " " + sessionTokens.access_token,
              "UMS-Authorization": umsTokens,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            this.emitter.emit("firmware_details_updated");

            let firmwareReleaseModal = bootstrap.Modal.getInstance(
              document.getElementById(this.id)
            );
            firmwareReleaseModal.hide();

            this.emitter.emit("popup_alert", {
              type: "success",
              text: "Firmware release was succesfuly changed!",
            });
          } else {
            process.appdata.umslogin();
          }
        });
    },
  },
};
</script>