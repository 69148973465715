<template>
  <div class="card">
    <div class="card-header bg-light d-flex">
      <i class="bi bi-app-indicator me-2"></i>
      Overview
      <span class="ms-auto text-muted small">Active</span>
    </div>
    <div class="card-body">
      <ul class="list-group">
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-person-circle me-3"></i>Profile Photo:
          </div>
          <div class="w-75 py-2 px-3">
            <img
              :src="me.picture"
              :alt="me.full_name"
              class="rounded-circle align-self-center"
              width="21"
              height="21"
              onerror="this.src='/images/person-circle.svg';"
            />
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-person-badge me-3"></i>First Name:
          </div>
          <div class="w-75 py-2 px-3">
            {{ me.first_name }}
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-person-badge me-3"></i>Last Name:
          </div>
          <div class="w-75 py-2 px-3">{{ me.last_name }}</div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-person-badge-fill me-3"></i>Job Title:
          </div>
          <div class="w-75 py-2 px-3">{{ me.job_title }}</div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-telephone me-3"></i>Phone Number:
          </div>
          <div class="w-75 py-2 px-3">{{ me.phone_number }}</div>
        </li>

        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-envelope me-3"></i>Email:
          </div>
          <div class="w-75 py-2 px-3">
            {{ me.email }}
          </div>
        </li>

        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-globe me-3"></i>Timezone:
          </div>
          <div class="w-75 py-2 px-3">
            {{ readableTimezone(me.timezone) }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Utils from "../../helpers/utils";
import Storage from "../../helpers/storage";

export default {
  name: "MyAccountOverview",
  components: {},
  data() {
    return {
      me: process.appdata.user,
      user_agent: {
        device: {},
        browser: {},
        os: {},
        ua: "",
        device_type: "",
      },
    };
  },
  mounted() {},
  methods: {
    capitalizeText(text, allwords) {
      return Utils.capitalizeText(text, allwords);
    },
    readableTimezone(timezoneCode) {
      return Utils.readableTimezone(timezoneCode);
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
  },
};
</script>
