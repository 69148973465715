<template>
  <div class="modal fade" tabindex="-1" id="firmwareAssetDelete">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete Firmware</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="alert alert-danger text-center">
                Are you sure you to delete {{asset_name || 'this firmware asset'}}?
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <button class="btn btn-secondary w-100" data-bs-dismiss="modal">
                Cancel
              </button>
            </div>
            <div class="col-md-6">
              <div class="btn btn-danger w-100" @click="delete_firmware_asset">
                Confirm
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Storage from "../../helpers/storage";

export default {
  name: "FirmwareAssetDelete",
  data() {
    return {
      firmware: {
        version: this.$route.params.firmware,
        model: this.$route.params.model
      },
      asset_name: null,
      asset_id: null,
    };
  },
  mounted() {
    this.emitter.on("delete_firmware_asset", (data) => {
      this.asset_id = data.id;
      this.asset_name = data.name;
      $('#firmwareAssetDelete').modal('show');
    });
  },
  methods: {
    delete_firmware_asset() {
      var sessionTokens = Storage.get("session_tokens", true),
        umsTokens = Storage.get("tokens");

      axios
        .delete(
          process.env.MIX_APP_URL + "/api/v1/firmware/" + this.firmware.model + "/" + this.firmware.version + "/assets/" + this.asset_id,
          {
            headers: {
              Authorization:
                sessionTokens.token_type + " " + sessionTokens.access_token,
              "UMS-Authorization": umsTokens,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            $('#firmwareAssetDelete').modal('hide');
            this.emitter.emit("firmware_asset_deleted", this.asset_id);

            this.emitter.emit("popup_alert", {
              type: "success",
              text: "Firmware asset was succesfuly deleted!",
            });
          } else {
            process.appdata.umslogin();
          }
        });
    },
  },
};
</script>