import axios from "axios";
import { createApp } from "vue";
import mitt from "mitt";

import App from "./App.vue";
import router from "./router";

import Storage from "./helpers/storage";
import RTAuth from "./helpers/rt-auth";

/* Import the vanillajs-datepicker stylesheet */
import "../node_modules/vanillajs-datepicker/dist/css/datepicker.min.css";

/* Import the custom stylesheet */
import "./assets/styles.css";

const Emitter = mitt();

process.appdata = {};
process.appdata.user = Storage.get("user", true);
process.appdata.tokens = Storage.get("tokens", true);

/* Check every minute if user's token is expired or user's session is revoked */
clearInterval(process.appdata.loginCheck);
process.appdata.loginCheck = setInterval(() => {
    process.appdata.getme();
}, 60000);


/* Login localy */
process.appdata.umslogin = () => {
    let tokens = JSON.parse(Storage.get("tokens"));

    axios({
        method: "post",
        url: "/api/v1/login",
        data: {
            "access_token": tokens.access_token
        },
    })
        .then(function (response) {
            if (null !== response.data.access_token) {
                Storage.set("session_tokens", JSON.stringify(response.data));

                window.location.href = "/";
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};

process.appdata.refreshTokens = (callback) => {
    let tokens = Storage.get("session_tokens", true);

    axios({
        method: "post",
        url: "/api/v1/refresh",
        data: {
            "refresh_token": tokens.refresh_token
        },
    })
        .then(function (response) {
            if (null !== response.data.access_token) {
                Storage.set("session_tokens", JSON.stringify(response.data));

                if (callback) {
                    callback();
                }
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};

/* Remove storage data and redirect to logout URL */
process.appdata.logout = () => {
    Storage.remove("tokens");
    Storage.remove("user");

    window.location.href = process.env.MIX_RT_UMS_SCHEME + "://" + process.env.MIX_RT_UMS_DOMAIN + "/logout?client_id=" + process.env.MIX_RT_UMS_CLIENT_ID
};


/* Get information about current user from UMS API */
process.appdata.getme = (callback) => {
    let tokens = Storage.get("tokens", true);

    if (new Date() > tokens.ttl) {
        RTAuth.requestToken();
    }

    axios.get(process.env.MIX_RT_UMS_URL + "me", {
        headers: {
            Authorization: tokens.access_token
        },
    }).then((response) => {
        Storage.set("user", JSON.stringify(response.data));

        if (callback) {
            callback();
        }
    }).catch(function (error) {
        RTAuth.refreshToken();
    });
}


/* Check for ongoing login attempt */
if (
    window.location.search.includes("code=") &&
    window.location.search.includes("state=")
) {
    RTAuth.accessToken(() => {
        process.appdata.getme(() => {
            process.appdata.umslogin();
        })
    });
} else {
    try {
        if (new Date() > process.appdata.tokens.ttl) {
            RTAuth.requestToken();
        }

        // Finally create the application instance after all information is retrieved
        const app = createApp(App);
        app.config.globalProperties.emitter = Emitter;
        app.use(router);
        app.mount("#app");
    } catch (err) {
        RTAuth.requestToken();
    }
}
