<template>
  <Breadcrumbs :breaditems="[{ title: 'Documentation' }]" />

  <div class="container">
    <div class="card">
      <div class="card-body">
        <div class="text-center">
          <img src="/images/empty.svg" class="img-fluid py-5 w-25" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "../components/Breadcrumbs.vue";

export default {
  name: "Documentation",
  components: { Breadcrumbs },
  data() {
    return {};
  },
};
</script>