<template>
  <div class="card">
    <div class="card-header bg-white d-flex justify-content-between">
      <div>
        <i class="bi bi-grid-3x3 me-2"></i>
        Assets
      </div>
      <button type="button" class="btn btn-outline-secondary border-0 py-0 px-1" data-bs-toggle="modal" data-bs-target="#newFirmwareAsset">
        <i class="bi bi-plus"></i> New
      </button>
    </div>
    <div class="list-group list-group-flush" v-if="assets.length > 0">
      <div class="list-group-item bg-light">
        <div class="row">
          <div class="col-md-7 border-end">
            <span>File</span>
          </div>
          <div class="col-md-2 border-end text-center">
            <span>Size</span>
          </div>
          <div class="col-md-3 text-end">
            <span>Upload Date</span>
          </div>
        </div>
      </div>
      <div v-for="asset in assets" v-bind:key="asset.id" class="list-group-item">
        <div class="row">
          <div class="col-md-7 border-end">
            <p class="d-block text-truncate mb-0">
              <b>{{asset.name}}</b>
              <i class="bi bi-x text-danger" @click="delete_asset(asset)" role="button"></i>
            </p>
            <p class="small text-muted d-block text-truncate mb-0" @click="copy_signature" role="button">
              <i class="bi bi-clipboard-check"></i>
              {{asset.signature}}
            </p>
          </div>
          <div class="col-md-2 border-end d-flex">
            <div class="align-self-center ms-auto">
              <p class="text-muted font-monospace small float-end mb-0 me-auto">{{ asset.file_size_formatted }}</p>
              <br/>
              <button
                type="button"
                class="btn btn-sm btn-link d-block text-decoration-none float-end p-0"
                @click="download_asset(asset)">
                Download
              </button>
            </div>
          </div>
          <div class="col-md-3">
            <div class="text-end">
              <p class="small text-muted mb-0">
                {{ timeAgo(asset.created_at) }}
              </p>
              <b class="d-block text-muted small">{{
                localMachineTime(asset.created_at)
              }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body" v-else>
      <div class="text-center">
        <img src="/images/empty.svg" class="img-fluid py-5 w-25" />
      </div>
    </div>
  </div>
  <ModalNewFirmwareAsset id="newFirmwareAsset" />
  <ModalFirmwareAssetDelete />
</template>

<script>
import axios from "axios";
var fileDownload = require("js-file-download");

import Storage from "../../helpers/storage";
import Utils from "../../helpers/utils";

import ModalNewFirmwareAsset from "../../components/modals/NewFirmwareAsset.vue";
import ModalFirmwareAssetDelete from "../../components/modals/FirmwareAssetDelete.vue";

export default {
  name: "FirmwareAssets",
  components: { ModalNewFirmwareAsset, ModalFirmwareAssetDelete },
  data() {
    return {
      firmware: {
        version: this.$route.params.firmware,
        model: this.$route.params.model
      },
      assets: [],
      asset: null
    };
  },
  mounted() {
    this.emitter.on("firmware_asset_deleted", (data) => {
      this.fetch_assets();
    });

    this.emitter.on("firmware_asset_uploaded", (data) => {
      this.fetch_assets();
    });

    this.fetch_assets()
  },
  methods: {
    fetch_assets() {
      var sessionTokens = Storage.get("session_tokens", true),
        umsTokens = Storage.get("tokens");
      let router = this.$router;

      axios
        .get(process.env.MIX_APP_URL + "/api/v1/firmware/" + this.firmware.model + "/" + this.firmware.version + "/assets", {
          headers: {
            Authorization:
              sessionTokens.token_type + " " + sessionTokens.access_token,
            "UMS-Authorization": umsTokens,
          },
        })
        .then((response) => {
          this.assets = response.data;

          this.assets.forEach((item) => {
            item.file_size_formatted = this.firmware_size_limit(item.size);
          });

        })
        .catch(function (error) {
          if (error.response && error.response.status == 404) {
            console.log('Error', error.message);
            router.push({name: 'NotFound' })
          }
        });
    },
    download_asset(asset) {
      var sessionTokens = Storage.get("session_tokens", true),
        umsTokens = Storage.get("tokens");

      axios
        .get(
          process.env.MIX_APP_URL +
            "/api/v1/firmware/" +
            this.firmware.model +
            "/" +
            this.firmware.version +
            "/assets/" + 
            asset.id,
          {
            responseType: 'blob',
            headers: {
              Authorization:
                sessionTokens.token_type + " " + sessionTokens.access_token,
              "UMS-Authorization": umsTokens,
            },
          }
        )
        .then((response) => {
          fileDownload(response.data, asset.name);
        });
    },
    delete_asset(asset) {
      this.emitter.emit('delete_firmware_asset', asset);
    },
    localMachineTime(dateString) {
      return Utils.localMachineTime(dateString);
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
    firmware_size_limit(kb) {
      let decimals = 2;
      let interval = 1024;
      let bytes = interval * kb;

      if (bytes === 0) return '0 Bytes';

      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      const i = Math.floor(Math.log(bytes) / Math.log(interval));

      return parseFloat((bytes / Math.pow(interval, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    copy_signature(asset) {
      var app = this;

      try {
        navigator.clipboard.writeText(asset.signature).then(function () {
          app.emitter.emit("popup_alert", {
            type: "success",
            text: "Asset signature was successfully copied to clipboard!",
          });
        });
      } catch (err) {
        app.emitter.emit("popup_alert", {
          type: "warning",
          text: "Your browser does not allow copying to clipboard or the application is loaded from non-secure context (HTTPS)",
        });
      }
    },
  }
};
</script>
