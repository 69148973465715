<template>
  <Breadcrumbs :breaditems="[{ title: 'Dashboard' }]" />

  <div class="container-xl">
    <div class="d-flex mb-2">
      <div class="me-2">
        <div class="input-group">
          <span class="input-group-text border-end-0 bg-white" id="basic-addon1"
            ><i class="bi bi-upc-scan"></i
          ></span>
          <select
            v-model="filter.model"
            class="form-select bg-white border-start-0 ps-0"
            style="max-width: 125px"
          >
            <option disabled="">Device Model</option>
            <option value="" selected="">All</option>
            <optgroup label="Gateways">
              <option
                v-for="gateway in models.gateways"
                v-bind:key="gateway.ref"
                :value="gateway.ref"
              >
                {{ gateway.name }}
              </option>
            </optgroup>
            <optgroup label="Modules">
              <option
                v-for="module in models.modules"
                v-bind:key="module.ref"
                :value="module.ref"
              >
                {{ module.name }}
              </option>
            </optgroup>
            <optgroup label="Sensors">
              <option
                v-for="sensor in models.sensors"
                v-bind:key="sensor.ref"
                :value="sensor.ref"
              >
                {{ sensor.name }}
              </option>
            </optgroup>
          </select>
        </div>
      </div>
      <div class="me-2">
        <div class="input-group">
          <span class="input-group-text border-end-0 bg-white" id="basic-addon1"
            ><i class="bi bi-circle"></i
          ></span>
          <select
            v-model="filter.release_type"
            class="form-select bg-white border-start-0 ps-0"
            style="max-width: 125px"
          >
            <option disabled="">Release Type</option>
            <option value="">All</option>
            <option value="production">Production</option>
            <option value="staging">Staging</option>
            <option value="development">Development</option>
          </select>
        </div>
      </div>
      <div class="ms-auto">
        <div class="input-group">
          <span class="input-group-text bg-white border-end-0"
            ><i class="bi bi-search"></i
          ></span>
          <input
            type="text"
            class="form-control"
            v-model="filter.search"
            v-on:keyup.enter="fetch_firmwares()"
            placeholder="Search..."
          />
        </div>
      </div>
      <div class="ms-2">
        <button
          type="button"
          class="
            btn btn-dark
            float-end
            text-decoration-none text-nowrap
            bd-highlight
          "
          data-bs-toggle="modal"
          data-bs-target="#newFirmware"
        >
          Upload Firmware
        </button>
      </div>
    </div>

    <div class="card mb-2">
      <div class="list-group list-group-flush">
        <div class="list-group-item bg-light">
          <div class="row">
            <div
              class="col-md-4 border-end d-flex justify-content-between"
              @click="sortBy('id')"
            >
              <span>Firmware Version</span>
              <i
                class="bi bi-arrow-down-up"
                :class="'id' === sortby ? 'text-primary' : ''"
              ></i>
            </div>
            <div
              class="col-md-2 border-end d-flex justify-content-between"
              @click="sortBy('model_ref')"
            >
              <span>Device Model</span>
              <i
                class="bi bi-upc-scan"
                :class="'model_ref' === sortby ? 'text-primary' : ''"
              ></i>
            </div>
            <div
              class="col-md-2 border-end d-flex justify-content-between"
              @click="sortBy('release_type')"
            >
              <span>Release Type</span>
              <i
                class="bi bi-circle"
                :class="'release_type' === sortby ? 'text-primary' : ''"
              ></i>
            </div>
            <div
              class="col-md-2 border-end d-flex justify-content-between"
              @click="sortBy('file_size')"
            >
              <span>File Size</span>
              <i
                class="bi bi-file-zip"
                :class="'file_size' === sortby ? 'text-primary' : ''"
              ></i>
            </div>
            <div
              class="col-md-2 d-flex justify-content-between"
              @click="sortBy('created_at')"
            >
              <span>Upload Date</span>
              <i
                class="bi bi-arrow-down-up"
                :class="'created_at' === sortby ? 'text-primary' : ''"
              ></i>
            </div>
          </div>
        </div>
        <router-link
          v-for="firmware in firmwares"
          v-bind:key="firmware.id"
          :to="'/firmwares/' + firmware.model_ref + '/' + firmware.major + '.' + firmware.minor + '.' + firmware.patch"
          class="list-group-item list-group-item-action"
        >
          <div class="row">
            <div class="col-md-4 border-end">
              <div class="d-flex align-items-center">
                <div
                  class="
                    flex-shrink-0
                    bg-primary bg-opacity-25
                    rounded-circle
                    d-flex
                  "
                  style="height: 45px; width: 45px"
                >
                  <i
                    class="
                      bi bi-file-zip
                      fs-5
                      rounded-circle
                      align-self-center
                      mx-auto
                      text-primary
                    "
                  ></i>
                </div>
                <div class="flex-grow-1 ms-3">
                  <b class="text-dark font-monospace small"
                    >Version
                    {{
                      firmware.major +
                      "." +
                      firmware.minor +
                      "." +
                      firmware.patch
                    }}</b
                  >
                  <p class="small text-muted mb-0">
                    {{ capitalizeText(firmware.release_impact) }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-2 border-end d-flex">
              <div class="align-self-center mx-auto">
                <span class="badge bg-secondary">{{
                  firmware.model.name
                }}</span>
              </div>
            </div>
            <div class="col-md-2 border-end d-flex">
              <div class="align-self-center mx-auto">
                <span
                  v-if="'production' === firmware.release_type"
                  class="badge bg-success"
                  >Production</span
                >
                <span
                  v-if="'staging' === firmware.release_type"
                  class="badge bg-warning"
                  >Staging</span
                >
                <span
                  v-if="'development' === firmware.release_type"
                  class="badge bg-info"
                  >Development</span
                >
              </div>
            </div>
            <div class="col-md-2 border-end d-flex">
              <div
                class="
                  align-self-center
                  text-muted
                  font-monospace
                  small
                  text-end
                  ms-auto
                "
              >
                <p class="small text-muted mb-0">{{ firmware.file_size_formatted }}</p>
                <b class="d-block text-muted small"
                  >{{ firmware.file_size_increase }}%</b
                >
              </div>
            </div>
            <div class="col-md-2">
              <div class="text-end">
                <p class="small text-muted mb-0">
                  {{ timeAgo(firmware.created_at) }}
                </p>
                <b class="d-block text-muted small">{{
                  localMachineTime(firmware.created_at)
                }}</b>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <pagination
        v-if="paginator.current_page"
        :records="paginator.total_items"
        :per-page="10"
        v-model="current_page"
        :options="paginatorOptions"
      ></pagination>
    </div>
  </div>

  <ModalNewFirmware id="newFirmware" />
</template>

<script>
import axios from "axios";
import Pagination from "v-pagination-3";

import Storage from "../helpers/storage.js";
import Utils from "../helpers/utils";

import Breadcrumbs from "../components/Breadcrumbs.vue";
import RTPagination from "../components/RTPagination.vue";

import ModalNewFirmware from "../components/modals/NewFirmware.vue";

export default {
  name: "Dashboard",
  components: { Breadcrumbs, Pagination, RTPagination, ModalNewFirmware },
  data() {
    return {
      paginatorOptions: {
        template: RTPagination,
      },
      paginator: {},
      filter: {
        model: "",
        release_type: "",
        version: "",
        search: "",
      },
      models: { modules: [], gateways: [], sensors: [] },
      firmwares: [],
      current_page: 1,
      sortby: "created_at",
      sortdir: 0,
    };
  },
  mounted() {
    this.emitter.on("firmware_uploaded", (data) => {
      this.fetch_firmwares();
    });

    this.fetch_models();
    this.fetch_firmwares();
  },
  methods: {
    fetch_models() {
      var sessionTokens = Storage.get("session_tokens", true),
        umsTokens = Storage.get("tokens");

      axios
        .get(process.env.MIX_APP_URL + "/api/v1/device-models", {
          headers: {
            Authorization:
              sessionTokens.token_type + " " + sessionTokens.access_token,
            "UMS-Authorization": umsTokens,
          },
        })
        .then((response) => {
          let i,
            modules = [],
            gateways = [],
            sensors = [];

          this.models = response.data;

          for (i = 0; i < response.data.length; i++) {
            switch (response.data[i].type) {
              case "module":
                modules.push(response.data[i]);
                break;
              case "gateway":
                gateways.push(response.data[i]);
                break;
              case "sensor":
                sensors.push(response.data[i]);
                break;
            }
          }

          this.models.modules = modules;
          this.models.gateways = gateways;
          this.models.sensors = sensors;
        })
        .catch((error) => {
          process.appdata.refreshTokens();
        });
    },
    fetch_firmwares() {
      var sessionTokens = Storage.get("session_tokens", true),
        umsTokens = Storage.get("tokens"),
        url =
          process.env.MIX_APP_URL +
          "/api/v1/firmware?page=" +
          this.current_page +
          "&sortBy=" +
          this.sortby +
          "&sortDir=" +
          this.sortdir;

      if ("" !== this.filter.model) {
        url += "&model=" + this.filter.model;
      }

      if ("" !== this.filter.release_type) {
        url += "&release_type=" + this.filter.release_type;
      }

      if ("" !== this.filter.search) {
        url += "&search=" + encodeURIComponent(this.filter.search);
      }

      axios
        .get(url, {
          headers: {
            Authorization:
              sessionTokens.token_type + " " + sessionTokens.access_token,
            "UMS-Authorization": umsTokens,
          },
        })
        .then((response) => {
          this.firmwares = response.data.data;
          this.paginator = response.data.meta;

          this.firmwares.forEach((item) => {
            item.file_size_formatted = this.firmware_size_limit(item.file_size);
            if (0 !== item.previous_file_size) {
              item.file_size_increase =
                Math.round((item.file_size * 100) / item.previous_file_size) -
                100;
            } else {
              item.file_size_increase = 0;
            }
          });
        })
        .catch((error) => {
          // process.appdata.refreshTokens();
        });
    },

    sortBy(column) {
      if (this.sortby === column) {
        this.sortdir = 1 - this.sortdir;
      } else {
        this.sortby = column;
        this.sortdir = 0;
      }

      this.fetch_firmwares();
    },

    localMachineTime(dateString) {
      return Utils.localMachineTime(dateString);
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
    capitalizeText(text) {
      return Utils.capitalizeText(text, false);
    },
    firmware_size_limit(kb) {
      let decimals = 2;
      let interval = 1024;
      let bytes = interval * kb;

      if (bytes === 0) return '0 Bytes';

      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      const i = Math.floor(Math.log(bytes) / Math.log(interval));

      return parseFloat((bytes / Math.pow(interval, i)).toFixed(dm)) + ' ' + sizes[i];
    },
  },
  watch: {
    current_page: function () {
      this.fetch_firmwares();
    },
    "filter.model": function () {
      this.fetch_firmwares();
    },
    "filter.release_type": function () {
      this.fetch_firmwares();
    },
  },
};
</script>