<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">User Agent</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-4 mb-3">
              <span class="fw-bold">Device Type</span>
            </div>
            <div class="col-md-8 mb-3">
              <div class="font-monospace">{{ device_type }}</div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 mb-3">
              <span class="fw-bold">OS</span>
            </div>
            <div class="col-md-8 mb-3">
              <div class="font-monospace">{{ os }}</div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 mb-3">
              <span class="fw-bold">Browser</span>
            </div>
            <div class="col-md-8 mb-3">
              <div class="font-monospace">{{ browser }}</div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 mb-3">
              <span class="fw-bold">Browser Version</span>
            </div>
            <div class="col-md-8 mb-3">
              <div class="font-monospace">{{ browser_version }}</div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <span class="fw-bold">User Agent</span>
            </div>
            <div class="col-md-8">
              <div
                class="font-monospace"
                style="white-space: nowrap; overflow-x: scroll"
              >
                {{ user_agent }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from "../../helpers/utils";

export default {
  name: "UserAgent",
  props: ["id"],
  data() {
    return {
      device_type: "",
      os: {},
      browser: {},
      browser_version: "",
      user_agent: "",
    };
  },
  mounted() {
    this.emitter.on("user_agent_loaded", (data) => {
      this.device_type = data.device_type
        ? this.capitalizeText(data.device_type)
        : "";
      this.os = data.os
        ? (data.os.name || "") + " · " + (data.os.version || "")
        : "";
      this.browser = data.browser ? data.browser.name : "";
      this.browser_version = data.browser ? data.browser.version : "";
      this.user_agent = data.ua ? data.ua : "";
    });
  },
  methods: {
    capitalizeText(text, allwords) {
      return Utils.capitalizeText(text, allwords);
    },
  },
};
</script>