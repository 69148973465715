<template>
  <div class="card mb-2">
    <div class="card-header bg-white">
      <i class="bi bi-app-indicator me-2"></i>
      Overview
    </div>
    <div class="card-body">
      <ul class="list-group">
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-hash me-3"></i>ID:
          </div>
          <div class="w-75 py-2 px-3">
            <span class="align-self-center small font-monospace">{{
              firmware.id
            }}</span>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-git me-3"></i>Firmware Version:
          </div>
          <div class="w-75 py-2 px-3">
            <span class="align-self-center small font-monospace">{{
              firmware.version
            }}</span>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-file-earmark-lock2 me-3"></i>Signature:
          </div>
          <div class="w-75 py-2 px-3">
            <span class="align-self-center small font-monospace">{{
              firmware.signature
            }}</span>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-upc-scan me-3"></i>Device Model:
          </div>
          <div class="w-75 py-2 px-3">
            <span class="align-self-center">{{ firmware.model.name }}</span>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-file-zip me-3"></i>File Size:
          </div>
          <div class="w-75 py-2 px-3">
            <span class="align-self-center small font-monospace"
              >{{ firmware.file_size_kb }} KB</span
            >
            <button
              type="button"
              class="btn btn-link float-end text-decoration-none p-0"
              @click="download_firmware"
            >
              Download
            </button>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-circle me-3"></i>Release Type:
          </div>
          <div class="w-75 py-2 px-3">
            <span
              v-if="'production' === firmware.release_type"
              class="badge bg-success"
              >Production</span
            >
            <span
              v-if="'staging' === firmware.release_type"
              class="badge bg-warning"
              >Staging</span
            >
            <span
              v-if="'development' === firmware.release_type"
              class="badge bg-info"
              >Development</span
            >

            <button
              type="button"
              class="btn btn-link float-end text-decoration-none p-0"
              data-bs-toggle="modal"
              data-bs-target="#firmwareRelease"
            >
              Update
            </button>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-calendar-day me-3"></i>Created:
          </div>
          <div class="w-75 py-2 px-3 small align-self-center">
            {{ localMachineTime(firmware.created_at) }}
            <span class="float-end">{{ timeAgo(firmware.created_at) }}</span>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-calendar-day-fill me-3"></i>Updated:
          </div>
          <div class="w-75 py-2 px-3 small align-self-center">
            {{ localMachineTime(firmware.updated_at) }}
            <span class="float-end">{{ timeAgo(firmware.updated_at) }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="card mt-2">
    <ul class="list-group list-group-flush">
      <li class="list-group-item">
        <div class="d-flex align-items-center">
          <div class="flex-shrink-0">
            <i class="bi bi-exclamation-triangle-fill fs-4 text-danger"></i>
          </div>
          <div class="flex-grow-1 ms-3">
            <b class="text-dark">Delete this Firmware</b>
            <p class="small text-muted mb-0">
              This action is irreversible so please use with caution.
            </p>
          </div>
          <button
            type="button"
            class="btn btn-outline-danger"
            data-bs-toggle="modal"
            data-bs-target="#firmwareDelete"
          >
            Delete Firmware
          </button>
        </div>
      </li>
    </ul>
  </div>

  <ModalFirmwareRelease id="firmwareRelease" />
  <ModalFirmwareDelete id="firmwareDelete" />
</template>

<script>
import axios from "axios";
var fileDownload = require("js-file-download");

import Storage from "../../helpers/storage";
import Utils from "../../helpers/utils";

import ModalFirmwareRelease from "../../components/modals/FirmwareRelease.vue";
import ModalFirmwareDelete from "../../components/modals/FirmwareDelete.vue";

export default {
  name: "FirmwareOverview",
  components: { ModalFirmwareRelease, ModalFirmwareDelete },
  data() {
    return {
      firmware: {
        version: this.$route.params.firmware,
        model: this.$route.params.model,
        file_name: "",
        original_file_name: ""
      },
    };
  },
  mounted() {
    this.emitter.on("firmware_details_updated", (data) => {
      this.fetch_firmware();
    });

    this.fetch_firmware();
  },
  methods: {
    fetch_firmware() {
      var sessionTokens = Storage.get("session_tokens", true),
        umsTokens = Storage.get("tokens");

      axios
        .get(
          process.env.MIX_APP_URL +
            "/api/v1/firmware/" +
            this.firmware.model +
            "/" +
            this.firmware.version,
          {
            headers: {
              Authorization:
                sessionTokens.token_type + " " + sessionTokens.access_token,
              "UMS-Authorization": umsTokens,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            this.emitter.emit("firmware_details_loaded", response.data);
            this.firmware = response.data;
          } else {
            process.appdata.umslogin();
          }
        });
    },
    download_firmware() {
      var sessionTokens = Storage.get("session_tokens", true),
        umsTokens = Storage.get("tokens");

      axios
        .get(
          process.env.MIX_APP_URL +
            "/api/v1/firmware/" +
            this.firmware.model.ref +
            "/" +
            this.firmware.version +
            "/image",
          {
            responseType: 'blob',
            headers: {
              Authorization:
                sessionTokens.token_type + " " + sessionTokens.access_token,
              "UMS-Authorization": umsTokens,
            },
          }
        )
        .then((response) => {
          fileDownload(response.data, this.firmware.file_name);
        });
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
    localMachineTime(dateString) {
      return Utils.localMachineTime(dateString);
    },
  },
};
</script>
