import { createRouter, createWebHistory } from "vue-router";

// Import all page components
import Dashboard from "./pages/Dashboard.vue";
import FirmwarePage from "./pages/Firmware.vue";
import FirmwareOverview from "./pages/fragments/FirmwareOverview.vue";
import FirmwareAssets from "./pages/fragments/FirmwareAssets.vue";
import FirmwareStats from "./pages/fragments/FirmwareStats.vue";
import FirmwareAnalytics from "./pages/fragments/FirmwareAnalytics.vue";
import FirmwareLogs from "./pages/fragments/FirmwareLogs.vue";

import LogsPage from "./pages/Logs.vue";
import LogPage from "./pages/Log.vue";

import DocumentationPage from "./pages/Documentation.vue";

import MyAccountPage from "./pages/MyAccount.vue";
import MyAccountOverview from "./pages/fragments/MyAccountOverview.vue";
import MyAccountLogs from "./pages/fragments/MyAccountLogs.vue";
import MyAccountLog from "./pages/fragments/MyAccountLog.vue";

import NotFound from "./pages/NotFound.vue";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      title: "Dashboard - Update Server"
    }
  },
  {
    path: "/firmwares/:model/:firmware",
    name: "FirmwarePage",
    component: FirmwarePage,
    meta: {
      title: "Firmware - Update Server"
    }, children: [
      {
        path: "",
        name: "FirmwareOverview",
        component: FirmwareOverview,
        meta: {
          title: "Overview - REMOTE TECH LTD",
          submenu: "overview"
        }
      },
      {
        path: "assets",
        name: "FirmwareAssets",
        component: FirmwareAssets,
        meta: {
          title: "Assets - REMOTE TECH LTD",
          submenu: "assets"
        }
      },
      {
        path: "stats",
        name: "FirmwareStats",
        component: FirmwareStats,
        meta: {
          title: "Stats - REMOTE TECH LTD",
          submenu: "stats"
        }
      },
      {
        path: "analytics",
        name: "FirmwareAnalytics",
        component: FirmwareAnalytics,
        meta: {
          title: "Analytics - REMOTE TECH LTD",
          submenu: "analytics"
        }
      },
      {
        path: "logs",
        name: "FirmwareLogs",
        component: FirmwareLogs,
        meta: {
          title: "Logs - REMOTE TECH LTD",
          submenu: "logs"
        }
      }
    ]
  },
  {
    path: "/logs",
    name: "LogsPage",
    component: LogsPage,
    meta: {
      title: "Logs - Update Server"
    }
  },
  {
    path: "/logs/:log",
    name: "LogPage",
    component: LogPage,
    meta: {
      title: "Log - Update Server"
    }
  },
  {
    path: "/documentation",
    name: "DocumentationPage",
    component: DocumentationPage,
    meta: {
      title: "Documentation - Update Server"
    }
  },
  {
    path: "/account",
    name: "MyAccountPage",
    component: MyAccountPage,
    meta: {
      title: "My Account - Update Server"
    }, children: [
      {
        path: "",
        name: "MyAccountOverview",
        component: MyAccountOverview,
        meta: {
          title: "Overview - REMOTE TECH LTD",
          submenu: "overview"
        }
      },
      {
        path: "logs",
        name: "MyAccountLogs",
        component: MyAccountLogs,
        meta: {
          title: "Logs - REMOTE TECH LTD",
          submenu: "logs"
        }
      },
      {
        path: "logs/:log",
        name: "MyAccountLog",
        component: MyAccountLog,
        meta: {
          title: "Log - REMOTE TECH LTD",
          submenu: "logs"
        }
      },
    ]
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
    meta: {
      title: "Not Found - REMOTE TECH LTD",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : "REMOTE TECH LTD";

  next();
})

export default router;
