<template>
  <div class="d-flex bd-highlight">
    <div class="flex-grow-1 me-2">
      <div class="input-group">
        <button class="input-group-text bg-white">
          <i class="bi bi-search"></i></button
        ><input type="text" class="form-control" placeholder="Search..." />
      </div>
    </div>
    <div class="bd-highlight me-2">
      <select v-model="filter.log_type" class="form-select">
        <option value="">All Logs</option>
        <option value="ticket_created">Support ticket created</option>
      </select>
    </div>
    <div class="bd-highlight me-2">
      <div class="input-group">
        <div class="input-group">
          <span class="input-group-text bg-white"
            ><i class="bi bi-calendar-week"></i></span
          ><input
            type="text"
            id="fromDate"
            placeholder="From Date"
            class="form-control datepicker-input"
            style="max-width: 120px"
          /><span class="input-group-text bg-white"
            ><i class="bi bi-calendar-week"></i></span
          ><input
            type="text"
            id="toDate"
            placeholder="To Date"
            class="form-control datepicker-input"
            style="max-width: 120px"
          />
        </div>
      </div>
    </div>
    <div class="bd-highlight">
      <button class="btn bg-white border text-decoration-none text-black">
        Export
      </button>
    </div>
  </div>
  <div class="card mt-2">
    <div class="card-header bg-light">
      <i class="bi bi-calendar-week me-2"></i> Activity Log
    </div>
    <div class="list-group list-group-flush activity-log">
      <router-link
        v-for="activity in activities"
        v-bind:key="activity.id"
        :data-user="activity.causer_id"
        :to="'/account/logs/' + activity.id"
        class="list-group-item list-group-item-action"
      >
        <div class="d-flex align-items-center">
          <div class="flex-shrink-0">
            <img
              src="/images/icons/person-circle.svg"
              :alt="activity.causer_name"
              class="rounded-circle align-self-center"
              width="36"
              height="36"
              onerror="this.src='/images/person-circle.svg';"
            />
          </div>
          <div class="flex-grow-1 ms-3">
            <span class="text-dark">{{ activity.causer_name }}</span>
            <p
              class="small text-muted mb-0 fw-normal"
              v-html="activity.plaintext ? activity.plaintext : '&nbsp;'"
            ></p>
          </div>
          <span
            class="text-muted float-end small text-nowrap"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title=""
            :data-bs-original-title="activity.created_at"
            >{{ timeAgo(activity.created_at) }}</span
          >
          <div class="float-end ms-3">
            <i class="bi bi-chevron-right align-self-center"></i>
          </div>
        </div>
      </router-link>
    </div>
    <pagination
      v-if="paginator.current_page"
      :records="paginator.total_items"
      :per-page="10"
      v-model="current_page"
      :options="paginatorOptions"
    ></pagination>
  </div>
</template>

<script>
import axios from "axios";
import Pagination from "v-pagination-3";
import { Datepicker } from "vanillajs-datepicker";

import Storage from "../../helpers/storage";
import Utils from "../../helpers/utils";
import RTPagination from "../../components/RTPagination.vue";

export default {
  name: "MyAccountLogs",
  components: { Pagination, RTPagination },
  data() {
    return {
      paginatorOptions: {
        template: RTPagination,
      },
      paginator: {},
      filter: {
        log_type: "",
        from_date: "",
        to_date: "",
        activity_name: "",
      },
      devices: [],
      current_page: 1,
    };
  },
  mounted() {
    let app = this;

    const fromDate = new Datepicker(document.getElementById("fromDate"), {
      clearBtn: true,
      autohide: true,
      maxDate: new Date(),
      format: "d M yyyy",
    });

    const toDate = new Datepicker(document.getElementById("toDate"), {
      clearBtn: true,
      autohide: true,
      minDate: new Date(),
      maxDate: new Date(),
      format: "d M yyyy",
    });

    document.getElementById("fromDate").addEventListener("changeDate", (ev) => {
      app.filter.from_date = Datepicker.formatDate(
        ev.detail.date,
        "yyyy-mm-dd"
      );

      toDate.setOptions({ minDate: ev.detail.date });

      if (toDate.getDate() < ev.detail.date) {
        toDate.setDate(ev.detail.date);
      }
    });

    document.getElementById("toDate").addEventListener("changeDate", (ev) => {
      this.filter.to_date = Datepicker.formatDate(ev.detail.date, "yyyy-mm-dd");
    });

    setTimeout(() => {
      jQuery("[data-bs-toggle='tooltip']").each(function () {
        jQuery(this).tooltip();
      });
    }, 1000);

    this.fetch_activities();
  },
  methods: {
    fetch_activities() {
      let url =
        process.env.MIX_RT_LOGS_ENDPOINT +
        "/activities?page=" +
        this.current_page +
        "&sort=created_at,desc&filter[causer_id]=" +
        process.appdata.user.id +
        "&filter[start]=" +
        this.filter.from_date +
        "&filter[end]=" +
        this.filter.to_date +
        "&filter[type]=" +
        this.filter.log_type +
        "&filter[wildcard]=" +
        encodeURIComponent(this.filter.activity_name);

      axios
        .get(url, {
          headers: {
            Authorization: process.env.MIX_RT_LOGS_SECRET,
          },
        })
        .then((response) => {
          this.activities = response.data.data;
          this.paginator = response.data.paginator;

          this.fetch_avatars();
        });
    },
    fetch_avatars() {
      let userIds = {};

      for (let item in this.activities) {
        userIds[this.activities[item].causer_id] = 1;
      }

      axios
        .get(
          process.env.MIX_RT_UMS_URL +
            "/users-avatars?users=" +
            Object.keys(userIds).join(","),
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            let users = response.data;

            for (let item in users) {
              jQuery("[data-user='" + users[item].id + "'] img").attr(
                "src",
                users[item].avatar
              );
            }
          } else {
            process.appdata.logout();
          }
        });
    },
    capitalizeText(text, allwords) {
      return Utils.capitalizeText(text, allwords);
    },
    readableTimezone(timezoneCode) {
      return Utils.readableTimezone(timezoneCode);
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
  },
  watch: {
    "filter.log_type": function () {
      this.fetch_activities();
    },
    "filter.from_date": function () {
      this.fetch_activities();
    },
    "filter.to_date": function () {
      this.fetch_activities();
    },
    current_page: function () {
      this.fetch_activities();
    },
  },
};
</script>
