<template>
  <div class="card">
    <div class="card-header bg-white">
      <i class="bi bi-grid-3x3 me-2"></i>
      Stats
    </div>
    <div class="card-body">
      <div class="text-center">
        <img src="/images/empty.svg" class="img-fluid py-5 w-25" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FirmwareStats",
  components: {},
  data() {
    return {
      firmware: {
        ref: this.$route.params.firmware,
      },
      stats: [],
    };
  },
  mounted() {},
  methods: {},
};
</script>
