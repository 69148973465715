<template>
  <div class="mt-auto">
    <footer class="footer border-top bg-white align-self-end">
      <div class="container-xl py-3">
        <span class="text-muted small">© 2022 REMOTE TECH LTD</span>
        <div class="float-end">
          <ul class="list-inline m-0">
            <li class="list-inline-item small">
              <a
                target="_blank"
                href="https://remote-tech.co.uk/contact"
                class="text-muted text-decoration-none"
                >Support</a
              >
            </li>
            <li class="list-inline-item small">
              <a
                target="_blank"
                href="https://remote-tech.co.uk/privacy-policy"
                class="text-muted text-decoration-none"
                >Privacy</a
              >
            </li>
            <li class="list-inline-item small">
              <a
                target="_blank"
                href="https://remote-tech.co.uk/terms-of-use"
                class="text-muted text-decoration-none"
                >Terms</a
              >
            </li>
          </ul>
        </div>
      </div>
    </footer>
  </div>
</template>