<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">New Firmware Asset</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 mb-3">
              <div id="modalDropArea">
                <i class="bi bi-file-binary-fill h1"></i>
                <p id="pFileInfo" class="text-primary lead mt-3">
                  Upload a file or drag and drop
                </p>
                <p class="text-secondary lead">Up to {{firmware_size_limit()}}</p>

                <input
                  type="file"
                  id="modalDropTarget"
                  :onchange="selectFile"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <button
                type="button"
                class="btn btn-primary w-100"
                :disabled="!file"
                @click="add_firmware_asset"
              >
                Add firmware asset
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Storage from "../../helpers/storage";

export default {
  name: "NewFirmwareAsset",
  props: ["id"],
  data() {
    return {
      firmware: {
        version: this.$route.params.firmware,
        model: this.$route.params.model
      },
      file: null,
    };
  },
  methods: {
    firmware_size_limit() {
      let decimals = 2;
      let interval = 1024;
      let bytes = interval * process.env.MIX_UPLOAD_SIZE_LIMIT_KB;

      if (bytes === 0) return '0 Bytes';

      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      const i = Math.floor(Math.log(bytes) / Math.log(interval));

      return parseFloat((bytes / Math.pow(interval, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    add_firmware_asset() {
      let app = this;

      var sessionTokens = Storage.get("session_tokens", true),
        umsTokens = Storage.get("tokens");

      var data = new FormData();
      data.append("asset", this.file, this.file.name);

      axios
        .post(process.env.MIX_APP_URL + "/api/v1/firmware/" + this.firmware.model + "/" + this.firmware.version + "/assets", data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization:
              sessionTokens.token_type + " " + sessionTokens.access_token,
            "UMS-Authorization": umsTokens,
          },
        })
        .then((response) => {
          this.emitter.emit("firmware_asset_uploaded");

          let newFirmwareAssetyModal = bootstrap.Modal.getInstance(
            document.getElementById(this.id)
          );
          newFirmwareAssetyModal.hide();

          this.emitter.emit("popup_alert", {
            type: "success",
            text: "Firmware Asset was succesfuly uploaded!",
          });
        })
        .catch(function (error) {
          if (error.response) {
            let errorMsg = "";

            for (let key in error.response.data) {
              errorMsg += error.response.data[key].join(" ");
            }

            app.emitter.emit("popup_alert", {
              type: "danger",
              text: errorMsg,
            });
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    },
    selectFile(ev) {
      var app = this;

      var fileObj = ev.target.files[0],
        reader = new FileReader();

      reader.onloadend = function (blob) {
        app.file = fileObj;

        document.getElementById("pFileInfo").innerHTML =
          fileObj.name + " (" + Math.round(fileObj.size / 1024) + "KB)";
      };

      var blob = fileObj.slice(0, fileObj.size);
      reader.readAsBinaryString(blob);
    },
  },
};
</script>