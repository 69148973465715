<template>
  <Breadcrumbs
    :breaditems="[
      { title: 'Dashboard', link: '/' },
      { title: 'Version ' + this.firmware.version },
    ]"
  />

  <div class="container-xl">
    <div class="row g-2">
      <div class="col-md-3 mt-2">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                <b class="text-dark font-monospace"
                  >Version
                  {{ firmware.version }}
                </b>
                <p class="small text-muted mb-0">
                  {{ capitalizeText(firmware.release_impact) }}
                </p>
              </div>
            </div>
          </div>
          <div class="list-group list-group-flush">
            <router-link
              :to="'/firmwares/' + firmware.model + '/' + firmware.version"
              :class="
                ('overview' === active_menu ? 'active ' : '') +
                'list-group-item list-group-item-action d-flex gap-2 align-items-center'
              "
              @click="setActiveMenu('overview')"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-app-indicator"></i>
                </div>
                <div class="flex-grow-1 ms-3">Overview</div>
              </div>
            </router-link>
            <router-link
              :to="'/firmwares/' + firmware.model + '/' + firmware.version + '/assets'"
              :class="
                ('assets' === active_menu ? 'active ' : '') +
                'list-group-item list-group-item-action d-flex gap-2 align-items-center'
              "
              @click="setActiveMenu('assets')"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-journals"></i>
                </div>
                <div class="flex-grow-1 ms-3">Assets</div>
              </div>
            </router-link>
            <router-link
              :to="'/firmwares/' + firmware.model + '/' + firmware.version + '/stats'"
              :class="
                ('stats' === active_menu ? 'active ' : '') +
                'list-group-item list-group-item-action d-flex gap-2 align-items-center'
              "
              @click="setActiveMenu('stats')"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-grid-3x3"></i>
                </div>
                <div class="flex-grow-1 ms-3">Stats</div>
              </div>
            </router-link>
            <router-link
              :to="'/firmwares/' + firmware.model + '/' + firmware.version + '/analytics'"
              :class="
                ('analytics' === active_menu ? 'active ' : '') +
                'list-group-item list-group-item-action d-flex gap-2 align-items-center'
              "
              @click="setActiveMenu('analytics')"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-bar-chart-line-fill"></i>
                </div>
                <div class="flex-grow-1 ms-3">Analytics</div>
              </div>
            </router-link>
            <router-link
              :to="'/firmwares/' + firmware.model + '/' + firmware.version + '/logs'"
              :class="
                ('logs' === active_menu ? 'active ' : '') +
                'list-group-item list-group-item-action d-flex gap-2 align-items-center'
              "
              @click="setActiveMenu('logs')"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-bug"></i>
                </div>
                <div class="flex-grow-1 ms-3">Logs</div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-md-9 mt-2">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Breadcrumbs from "../components/Breadcrumbs.vue";

import Storage from "../helpers/storage";
import Utils from "../helpers/utils";

export default {
  name: "Firmware",
  components: { Breadcrumbs },
  data() {
    return {
      active_menu: "overview",
      firmware: {
        version: this.$route.params.firmware,
        model: this.$route.params.model
      },
    };
  },
  mounted() {
    this.emitter.on("firmware_details_updated", (data) => {
      this.fetch_firmware();
    });

    this.fetch_firmware();
  },
  methods: {
    fetch_firmware() {
      var sessionTokens = Storage.get("session_tokens", true),
        umsTokens = Storage.get("tokens");
      let router = this.$router;

      axios
        .get(process.env.MIX_APP_URL + "/api/v1/firmware/" + this.firmware.model + "/" + this.firmware.version, {
          headers: {
            Authorization:
              sessionTokens.token_type + " " + sessionTokens.access_token,
            "UMS-Authorization": umsTokens,
          },
        })
        .then((response) => {
          this.firmware = response.data;
          this.firmware.model = response.data.model.ref;
        })
        .catch(function (error) {
          if (error.response && error.response.status == 404) {
            console.log('Error', error.message);
            router.push({name: 'NotFound' })
          };
        });
    },
    setActiveMenu(menu) {
      this.active_menu = menu;
    },
    capitalizeText(text) {
      return Utils.capitalizeText(text, true);
    },
  },
};
</script>