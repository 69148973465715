<template>
  <div class="card-footer bg-light d-flex d-flex justify-content-between">
    <nav>
      <ul class="pagination pagination-sm m-0">
        <li class="page-item disabled me-auto">
          <a class="page-link" tabindex="-1" aria-disabled="true">{{
            props.page + " of " + props.totalPages
          }}</a>
        </li>
      </ul>
    </nav>

    <ul v-show="props.showPagination" class="pagination pagination-sm m-0">
      <li class="page-item me-auto" :class="1 === props.page ? 'disabled' : ''">
        <button
          type="button"
          class="page-link"
          @click="props.setFirstPage()"
          tabindex="-1"
        >
          <i class="bi bi-skip-start"></i>
        </button>
      </li>
      <li class="page-item me-auto" :class="1 === props.page ? 'disabled' : ''">
        <button
          type="button"
          class="page-link"
          @click="props.prev()"
          tabindex="-1"
        >
          <i class="bi bi-arrow-left"></i>
        </button>
      </li>
      <li
        v-for="page in props.pages"
        :key="page"
        class="page-item"
        :class="props.pageClasses(page)"
        v-on="props.pageEvents(page)"
      >
        <button type="button" v-bind="props.aProps" :class="props.theme.link">
          {{ page }}
        </button>
      </li>

      <li class="page-item me-auto" :class="props.totalPages === props.page ? 'disabled' : ''">
        <button
          type="button"
          class="page-link"
          @click="props.next()"
          tabindex="-1"
        >
          <i class="bi bi-arrow-right"></i>
        </button>
      </li>

      <li class="page-item me-auto" :class="props.totalPages === props.page ? 'disabled' : ''">
        <button
          type="button"
          class="page-link"
          @click="props.setLastPage()"
          tabindex="-1"
        >
          <i class="bi bi-skip-end"></i>
        </button>
      </li>
    </ul>

    <nav>
      <ul class="pagination pagination-sm m-0">
        <li class="page-item disabled">
          <a class="page-link" tabindex="-1" aria-disabled="true">
            {{ props.records + " results" }}
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "RTPagination",
  props: ["props"],
};
</script>
